/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import Introcards from '../components/Introcards';
import '../App.css';
import '../components/Menubar.css';
import Menubar from '../components/Menubar';
import Footer from '../components/Footer';
// import Announce from '../components/Announce';
import usePageTracking from '../components/PageTracking';
import updateMetaDescription from '../components/DescriptTag';
import updateMetaKeywords from '../components/KeywordTag';
import HealthcareBanner from '../components/HealthcareBanner';
import CiCdFlow from '../components/CiCdFlow';
import DevSecOpsTools from '../components/DevSecOpsTools';

function Home() {
  const [pageTitle, setPageTitle] = useState('Appddiction Studio Software Development, DevSecOps, Agile Training, Healthcare Staffing');
  useEffect(() => {
    document.title = pageTitle; // Set the page title to the current state value
  }, [pageTitle]);

  // Update meta description dynamically
  useEffect(() => {
    // eslint-disable-next-line max-len
    updateMetaDescription('In the fast-paced world of software development, SAFe Agile and Scrum practices drive efficient workflows. Our comprehensive training equips contractors with DevSecOps skills. We also provide healthcare staffing services.');
  }, []);

  // Update meta keywords dynamically
  useEffect(() => {
    // eslint-disable-next-line max-len
    updateMetaKeywords('Appddiction Studio, Software Development, DevSecOps, Agile Training, Healthcare Staffing');
  }, []);

  usePageTracking();

  return (
    <>
      <Menubar />
      {/* <Announce /> */}
      <Introcards />
      <CiCdFlow />
      <DevSecOpsTools />
      <HealthcareBanner />
      <Footer />
    </>
  );
}
export default Home;

